import type { NextPage } from "next";
import Link from "next/link";
import { Button, styled, Typography } from "@mui/material";
import FadeIn from "react-fade-in";
import { useContext, useState } from "react";
import { NotificationContext } from "../context/NotificationContextProvider";
import { useRouter } from "next/router";

const PageContainer = styled("div")`
  height: 100vh;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 900px) {
    padding: 40px 20px;
    justify-content: flex-start;
  }
`;

const PageContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 800px;
`;

const OptionButton = styled(Button)`
  width: 100%;
  padding: 15px;
  margin: 15px 0px;
`;

const Options: React.FC = () => {
  const [emailInUse, setEmailInUse] = useState<boolean>(false);

  const notificationContext = useContext(NotificationContext);
  const router = useRouter();

  function onCreateAccount() {
    router.push("/create-account/personal-details");
  }

  function onLoginNavigate() {
    window.location.assign("https://live.waterwatch.io/login");
  }

  return (
    <PageContainer>
      <FadeIn>
        <PageContent>
          <div>
            <Typography variant={"body1"}>Just purchased a new sensor?</Typography>
            <OptionButton variant={"contained"} onClick={onCreateAccount}>
              Create Account
            </OptionButton>
          </div>
          <div>
            <Typography variant={"body1"}>Already have an account?</Typography>
            <OptionButton variant={"contained"} onClick={onLoginNavigate}>
              Log in
            </OptionButton>
          </div>
          <div>
            <Typography variant={"body1"}>
              Looking to access someone&apos;s tank monitor?
            </Typography>
            <Typography variant={"caption"}>
              View our{" "}
              <Link href="https://help.waterwatch.io/3.0.1/share-access">step-by-step</Link>{" "}
              instructions
            </Typography>
          </div>
        </PageContent>
      </FadeIn>
    </PageContainer>
  );
};

const Home: NextPage = () => {
  return <Options />;
};

export default Home;
